/* OCW styles */

//@plone-gray-darker:				lighten(#000, 20%);   // #333
//@plone-gray-dark:					#a06d0e;   // #4c4c4c
//@plone-gray:						#f6ae2a;   // #696969
//@plone-gray-light:				#fbc45f; // #767676 //discreet
//@plone-gray-lighter:			#fbd288;   // #ccc //disableds like


@base-dark: 	#a06d0e;
@base:		#f6ae2a;
@base-light:    #fbc45f;
@base-lighter:	#fbd288;


@plone-sitenav-bg: @base;
@plone-sitenav-hover-bg:          #363636;
@plone-sitenav-link-hover-bg:     #363636;


@plone-portlet-header-bg: @base-lighter;
//@plone-portlet-border: @plone-border-base @base-lighter;
//@plone-portlet-contrast-bg:					@base-lighter;
//@plone-portlet-list-hover-bg: @base-lighter;
@plone-portlet-footer-bg: @base-lighter;

@plone-link-color-on-dark: #060606;
@plone-footer-bg: @base-dark;
@plone-page-header-border-color: @base-lighter;


#content-header {
    background-image:url('ocw_images/frontal.jpg');
    background-repeat:repeat;
}
#portal-header {
margin-bottom: 0px;
padding-bottom: 20px;
margin-top: 0px;
padding-top: 15px;
}
#portal-logo{
  color: @base;
  &:hover, &:focus {
    text-decoration: none;
  }
  &:after{
    content: " OpenCourseWare";
    font-size: xxx-large;
    font-weight: bold;
  }
}

#portal-searchbox label{
    color: white;
}

#personaltools-login {
    color: white;
}


// font-family:"Fontello";
//.contenttype-file #crud-edit-File-widgets-view_title::before {
//    content: '\e811';
//}
//e83d rss
