/* our custom styles */

/* TinyMCE templates */

// image-grid-2x2:
.image-grid-2x2 {
    padding: 0;
    margin: 0 0 @plone-grid-gutter-width 0;
    list-style: none;
    .make-row();

    > li {
        .make-xs-column(12);
        .make-sm-column(6);
        margin-top: @plone-grid-gutter-width;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
}
